import React, { useRef, useState } from 'react'
import { useFormik, Form, FormikProvider } from 'formik'
import { useTranslation } from 'react-i18next'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import ReCAPTCHA from 'react-google-recaptcha'

import { Yup } from 'shared/lib'
import useLogin from 'shared/store/useLogin'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'

import Box from '@mui/material/Box'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { sendResetEmailService } from './services'

const VALIDATION_SCHEMA = Yup.object().shape({
   email: Yup.string().email().min(3).max(64).required(),
})

type InitialValues = {
   email: string
}

export default function RemindPasswordForm() {
   const { t } = useTranslation(['TRANSLATION', 'HOMEPAGE'])
   const { isLoading } = useLogin()
   const [recaptcha, setRecaptcha] = useState('')
   const { addNotification } = useNotificationContext()
   const formik = useFormik<InitialValues>({
      initialValues: {
         email: '',
      },
      validationSchema: VALIDATION_SCHEMA,
      onSubmit: handleSubmit,
   })
   const recaptchaRef = useRef<ReCAPTCHA>(null)

   const { values, errors, touched, getFieldProps } = formik

   function handleSubmit() {
      const payload = {
         email: values.email,
         recaptcha,
      }
      sendResetEmailService(payload)
         .then((res) => {
            if (res.status === 200) {
               addNotification(t('HOMEPAGE:remindForm.successSend'), 'success')
            }
         })
         .catch((error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data.errors[0].code || t('HOMEPAGE:remindForm.failedSend'), 'error')
            } else {
               addNotification(t('HOMEPAGE:remindForm.failedSend'), 'error')
            }
         })
   }
   return (
      <FormikProvider value={formik}>
         <Form autoComplete="off" noValidate>
            <Stack spacing={3}>
               <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label={t('TRANSLATION:formFields.email')}
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email && t(errors.email)}
               />
            </Stack>
            <Box sx={{ display: 'flex', justifyContent: 'center' }} mt={5}>
               <ReCAPTCHA
                  sitekey="6LebPrIUAAAAAEtQ6YCkPH5RO-V1WNeAn5-dNCOc"
                  ref={recaptchaRef}
                  onChange={(value) => setRecaptcha(value || '')}
               />
            </Box>
            <ContainedButton
               fullWidth
               type="submit"
               variant="contained"
               disabled={Boolean(errors.email) || isLoading || !recaptcha}
               sx={{ mt: 4 }}
            >
               {t('HOMEPAGE:remindForm.remind')}
            </ContainedButton>
         </Form>
      </FormikProvider>
   )
}
