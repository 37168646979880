import { httpClient } from 'shared/lib'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { AxiosError } from 'axios'
import consoleMessage from 'shared/utils/helpers/consoleMessage'
import { CsvPreferencesType, Result } from '../../../types'

export default function createConfigService(locationUuid: string, payload: CsvPreferencesType) {
   let errMessage

   return httpClient
      .post<Result>(`locations/${locationUuid}/location-preferences`, payload)
      .then((res) => ({
         status: res.status,
      }))
      .catch((error: AxiosError<ErrorType> | Error) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('LOCATIONS createConfig service', errMessage, 'error')
         throw error
      })
}
