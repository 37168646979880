import { FormikProvider, Form, useFormik } from 'formik'
import { useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

import { LocationDetailsType, LocationType, SaveLocationDetailsPayload } from 'shared/store/useLocations/types'
import { findCountryKeyByName } from 'shared/utils/helpers/findCountryName'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useDataLocationsMutation } from 'shared/store/useLocations'
import useCountriesQuery from 'shared/store/useCountries'
import { UserRoles } from 'shared/consts'

import { useSearchParams } from 'react-router-dom'
import { InfinityThresholdType, LocationAddressOptionType } from '../../types'
import AutoOrderingSettings from '../AutoOrderingSettings'
import LocationDetailsForm from '../LocationDetailsForm'
import { locationDetailsSchema } from '../../schema'
import { LocationTabContainer } from '../../styles'
import AdminDetailsForm from '../AdminDetailsForm'
import MarkupSettings from '../MarkupSettings'

interface Props {
   mainLocationUuid: string
   values?: LocationType
   openDeleteLocationModal: () => void
   roles: UserRoles[]
}

export default function LocationsSettings({ mainLocationUuid, roles, values, openDeleteLocationModal }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'LOCATIONS'])
   const [activeTab, setActiveTab] = useState(0)
   const [infinityThresholdValues, setInfinityThresholdValues] = useState<InfinityThresholdType>({
      multiplier: 1,
      addend: 0,
   })
   const [disabledButton, setDisabledButton] = useState(false)
   const theme = useTheme()
   const [searchParams] = useSearchParams()
   const smallerScreen = useMediaQuery(theme.breakpoints.down('lg'))
   const { data: countriesData } = useCountriesQuery()
   const { mutate } = useDataLocationsMutation()
   const [options, setOptions] = useState<LocationAddressOptionType>({
      voivodeshipOptions: [],
      countyOptions: [],
   })

   const formik = useFormik<LocationDetailsType>({
      initialValues: {
         locationSettings: {
            uuid: '',
            name: '',
            address: {
               street: '',
               zipCode: '',
               city: '',
               houseNumber: '',
               country: '',
               voivodeship: '',
               county: '',
            },
            shippingAddress: {
               street: '',
               zipCode: '',
               city: '',
               houseNumber: '',
            },
            orderReflinkSettings: {
               address: '',
               companyName: '',
               phoneNumber: '',
               defaultCartDescription: '',
            },
         },
         autoCartsSettings: {
            autoOrderPreferences: {
               preferredWholesalesUuid: [],
               priceConditionPercentage: null,
               priceConditionAmount: null,
               addPartialQuantityToCart: false,
            },
            clientCartPreferences: {
               instantOrder: false,
            },
            locationUuid: null,
         },
         markupSettings: {
            priceType: 'NET',
            thresholds: [],
         },
      },
      validationSchema: locationDetailsSchema,
      onSubmit: handleSubmit,
   })

   const { setValues, isValid } = formik

   function handleSubmit() {
      if (values && countriesData) {
         const newSettings: SaveLocationDetailsPayload = {
            locationSettings: {
               address: {
                  city: formik.values.locationSettings.address.city,
                  zipCode: formik.values.locationSettings.address.zipCode,
                  street: formik.values.locationSettings.address.street,
                  houseNumber: formik.values.locationSettings.address.houseNumber,
                  country: findCountryKeyByName(countriesData, formik.values.locationSettings.address.country),
                  county: formik.values.locationSettings.address.county,
                  voivodeship: formik.values.locationSettings.address.voivodeship,
               },
               shippingAddress: {
                  city: formik.values.locationSettings.shippingAddress.city,
                  zipCode: formik.values.locationSettings.shippingAddress.zipCode,
                  street: formik.values.locationSettings.shippingAddress.street,
                  houseNumber: formik.values.locationSettings.shippingAddress.houseNumber,
               },
               orderReflinkSettings: {
                  address: formik.values.locationSettings.orderReflinkSettings?.address,
                  companyName: formik.values.locationSettings.orderReflinkSettings?.companyName,
                  phoneNumber: formik.values.locationSettings.orderReflinkSettings?.phoneNumber,
                  defaultCartDescription: formik.values.locationSettings.orderReflinkSettings?.defaultCartDescription,
               },
               locationName: formik.values.locationSettings.name,
               uuid: formik.values.locationSettings.uuid,
            },
            autoCartsSettings:
               roles.includes(UserRoles.PRO) || roles.includes(UserRoles.ULTRA)
                  ? formik.values.autoCartsSettings
                  : null,
            markupSettings: {
               priceType: formik.values.markupSettings.priceType,
               thresholds: [
                  ...formik.values.markupSettings.thresholds,
                  {
                     ...infinityThresholdValues,
                     end: null,
                     start: Number(getLastThresholdEndValue()),
                  },
               ],
            },
         }
         mutate({ payload: newSettings, uuid: values.locationSettings.uuid })
      }
   }

   function getLastThresholdEndValue() {
      const lastElement = formik.values.markupSettings.thresholds[formik.values.markupSettings.thresholds.length - 1]
      if (lastElement) {
         const { end } = lastElement
         if (end) {
            return (end + 0.01).toFixed(2)
         }
         return 0
      }
      return 0
   }

   function renderTabContentForBasic() {
      if (values) {
         switch (activeTab) {
            case 0:
               return (
                  <LocationDetailsForm
                     roles={roles}
                     openDeleteLocationModal={() => openDeleteLocationModal()}
                     mainLocationUuid={mainLocationUuid}
                     options={options}
                  />
               )
            case 1: {
               return (
                  <MarkupSettings
                     infinityThreshold={infinityThresholdValues}
                     setInfinityThreshold={(infinityValues: InfinityThresholdType) =>
                        setInfinityThresholdValues(infinityValues)
                     }
                  />
               )
            }
            default:
               return null
         }
      }
      return null
   }

   function renderSaveButton() {
      if (roles.includes(UserRoles.PRO) || roles.includes(UserRoles.ULTRA)) {
         if (activeTab !== 1) {
            return (
               <ContainedButton
                  disabled={!isValid || disabledButton}
                  sx={{ alignSelf: 'center' }}
                  variant="contained"
                  onClick={handleSubmit}
               >
                  {t('TRANSLATION:save')}
               </ContainedButton>
            )
         }
         return null
      }
      return (
         <ContainedButton
            disabled={!isValid || disabledButton}
            sx={{ alignSelf: 'center' }}
            variant="contained"
            onClick={handleSubmit}
         >
            {t('TRANSLATION:save')}
         </ContainedButton>
      )
   }

   function renderTabContent() {
      if (values) {
         switch (activeTab) {
            case 0:
               return (
                  <LocationDetailsForm
                     roles={roles}
                     openDeleteLocationModal={() => openDeleteLocationModal()}
                     mainLocationUuid={mainLocationUuid}
                     options={options}
                  />
               )
            case 1: {
               return (
                  <Box
                     sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        py: 2,
                        px: { md: '25%', xs: 0 },
                        minHeight: 400,
                     }}
                  >
                     {roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) && (
                        <AdminDetailsForm mainLocationUuid={mainLocationUuid} values={values} />
                     )}
                  </Box>
               )
            }
            case 2: {
               return <AutoOrderingSettings locationUuid={values.locationSettings.uuid} />
            }
            case 3: {
               return (
                  <MarkupSettings
                     infinityThreshold={infinityThresholdValues}
                     setInfinityThreshold={(infinityValues: InfinityThresholdType) =>
                        setInfinityThresholdValues(infinityValues)
                     }
                  />
               )
            }
            default:
               return null
         }
      }
      return null
   }

   useEffect(() => {
      const isRedirect = searchParams.get('clientCarts') === 'true'
      if (isRedirect && (roles.includes(UserRoles.PRO) || roles.includes(UserRoles.ULTRA))) {
         setActiveTab(2)
      }
   }, [])

   useEffect(() => {}, [formik.values])

   useEffect(() => {
      if (values) {
         const { thresholds } = formik.values.markupSettings
         if (
            (thresholds.length > 0 && !thresholds[thresholds.length - 1].end) ||
            (thresholds.length > 0 && thresholds.find((item) => item.end && item.start > item.end))
         ) {
            setDisabledButton(true)
         } else {
            setDisabledButton(false)
         }
      } else {
         setDisabledButton(false)
      }
   }, [formik.values])

   useEffect(() => {
      if (countriesData && formik.values && values && formik.values.locationSettings.address.country === 'Polska') {
         const countryKey = findCountryKeyByName(countriesData, formik.values.locationSettings.address.country)
         if (countryKey) {
            const selectedCountry = countriesData[countryKey]
            if (selectedCountry) {
               setOptions({
                  voivodeshipOptions: Object.keys(selectedCountry.voivodeships).map((item) => ({
                     name: item,
                     value: item,
                  })),
                  countyOptions: formik.values.locationSettings.address.voivodeship
                     ? selectedCountry.voivodeships[formik.values.locationSettings.address.voivodeship].map((item) => ({
                          name: item,
                          value: item,
                       }))
                     : [],
               })
            }
         }
      }
   }, [countriesData, formik.values, values])

   useEffect(() => {
      if (values && countriesData) {
         setValues({
            ...values,
            locationSettings: {
               ...values.locationSettings,
               address: {
                  ...values.locationSettings.address,
                  country: countriesData[values.locationSettings.address.country].name,
               },
               orderReflinkSettings: {
                  companyName: values.locationSettings.orderReflinkSettings?.companyName || '',
                  address: values.locationSettings.orderReflinkSettings?.address || '',
                  phoneNumber: values.locationSettings.orderReflinkSettings?.phoneNumber || '',
                  defaultCartDescription: values.locationSettings.orderReflinkSettings?.defaultCartDescription || '',
               },
            },
            autoCartsSettings: {
               ...values.autoCartsSettings,
               autoOrderPreferences: {
                  ...values.autoCartsSettings.autoOrderPreferences,
                  addPartialQuantityToCart:
                     values.autoCartsSettings.autoOrderPreferences.addPartialQuantityToCart || false,
                  preferredWholesalesUuid: values.autoCartsSettings.autoOrderPreferences.preferredWholesalesUuid || [],
                  priceConditionAmount: values.autoCartsSettings.autoOrderPreferences.priceConditionAmount || 0,
                  priceConditionPercentage: values.autoCartsSettings.autoOrderPreferences.priceConditionPercentage || 0,
               },
               clientCartPreferences: {
                  instantOrder: values.autoCartsSettings.clientCartPreferences.instantOrder || false,
               },
               locationUuid: values.autoCartsSettings.locationUuid || null,
            },
            markupSettings: {
               priceType: values.markupSettings.priceType || 'NET',
               thresholds: values.markupSettings.thresholds ? values.markupSettings.thresholds.slice(0, -1) : [],
            },
         })
         if (values.markupSettings.thresholds && values.markupSettings.thresholds.length > 0) {
            setInfinityThresholdValues({
               multiplier: values.markupSettings.thresholds[values.markupSettings.thresholds.length - 1].multiplier,
               addend: values.markupSettings.thresholds[values.markupSettings.thresholds.length - 1].addend,
            })
         }
      }
   }, [countriesData, values])

   useEffect(() => {
      if (
         options.countyOptions.length > 0 &&
         !options.countyOptions.some((item) => item.name === formik.values.locationSettings.address.county)
      ) {
         formik.setFieldValue('locationSettings.address.county', '')
      }
   }, [options])

   return (
      <Grid item xs={12} md={10}>
         <Box sx={{ backgroundColor: 'rgba(255,255,255,0.7)' }}>
            <FormikProvider value={formik}>
               {formik.values && options && (
                  <Form>
                     <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                           variant={smallerScreen ? 'fullWidth' : 'standard'}
                           centered
                           sx={{ display: 'flex', justifyContent: 'center' }}
                           value={activeTab}
                           onChange={(e, value) => setActiveTab(value)}
                        >
                           <Tab sx={{ fontSize: 12 }} value={0} label={t('LOCATIONS:tabsLabels.locationData')} />
                           {roles.includes(UserRoles.ULTRA) && roles.includes(UserRoles.MAIN_LOCALIZATION_ADMIN) && (
                              <Tab sx={{ fontSize: 12 }} value={1} label={t('LOCATIONS:tabsLabels.adminData')} />
                           )}
                           {(roles.includes(UserRoles.PRO) || roles.includes(UserRoles.ULTRA)) && (
                              <Tab sx={{ fontSize: 12 }} value={2} label={t('LOCATIONS:tabsLabels.csvCartsSettings')} />
                           )}
                           <Tab
                              sx={{ fontSize: 12 }}
                              value={roles.includes(UserRoles.PRO) || roles.includes(UserRoles.ULTRA) ? 3 : 1}
                              label={t('LOCATIONS:tabsLabels.markupSettings')}
                           />
                        </Tabs>
                     </Box>
                     <LocationTabContainer>
                        {roles.includes(UserRoles.PRO) || roles.includes(UserRoles.ULTRA)
                           ? renderTabContent()
                           : renderTabContentForBasic()}
                        {renderSaveButton()}
                     </LocationTabContainer>
                  </Form>
               )}
            </FormikProvider>
         </Box>
      </Grid>
   )
}
