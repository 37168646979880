import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { useNotificationContext } from 'shared/context/Notifications'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import useLogin from 'shared/store/useLogin'

import isAxiosError from 'shared/utils/helpers/isAxiosError'
import sendMessageService from '../Contact/sendMessageService'
import MissingProducts from './components/MissingProducts'
import OrderedTable from './components/OrderedTable'
import { en, pl, de } from './locales'

export default function OrderSummary() {
   const location = useLocation()
   const { user } = useLogin()
   const [searchParams] = useSearchParams()
   const { addNotification } = useNotificationContext()
   const state =
      searchParams.get('type') === 'BUY_NOW'
         ? (location.state as OrderSummaryType)
         : (location.state as BuyNowSummaryType)
   const { i18n, t } = useTranslation('ORDER_SUMMARY')
   i18n.addResourceBundle('pl-PL', 'ORDER_SUMMARY', pl)
   i18n.addResourceBundle('en-EN', 'ORDER_SUMMARY', en)
   i18n.addResourceBundle('de-DE', 'ORDER_SUMMARY', de)
   const navigate = useNavigate()
   const [isReported, setIsReported] = useState(false)
   const [isReporting, setIsReporting] = useState(false)

   function handleReportProblem(orderUuid: string, cartUuid: string, product: OrderProductType[]) {
      setIsReporting(true)
      const date = new Date()
      sendMessageService({
         typeMessage: 'Problem with order',
         message: `Problem occured in order. uuid: ${orderUuid}, cartUuid: ${cartUuid}, userUuid: ${
            user.uuid
         }, date: ${date.toLocaleString()}
         ${product
            .filter((item) => item.status !== 'AVAILABLE')
            .map((item) => `${item.productUuid} | ${item.wholesaleName} | ${item.wholesaleErrorMessage} \n`)}
        `,
      })
         .then((res) => {
            if (res.status === 204) {
               addNotification('successSend', 'success')
               setIsReported(true)
            }
         })
         .catch((error) => {
            if (isAxiosError<ErrorType>(error)) {
               addNotification(error.response?.data.errors[0].code || 'failedSave', 'error')
            } else {
               addNotification('failedSend', 'error')
            }
         })
         .finally(() => setIsReporting(false))
   }

   function renderContent() {
      if (Array.isArray(state)) {
         return state.map((order) => {
            return (
               <>
                  {order.product.some((item) => item.wholesaleName === 'Motorro') ? (
                     <Typography fontWeight="bold" color="red">
                        {t('motorroProducts')}
                     </Typography>
                  ) : (
                     state.length > 1 && <Typography fontWeight="bold">{t('otherProducts')}</Typography>
                  )}
                  <OrderedTable
                     netDeliveryCost={order.netDeliveryCost}
                     grossDeliveryCost={order.grossDeliveryCost}
                     formattedGrossValue={order.formattedGrossValue}
                     data={order.product.filter((item) => item.status !== 'PARTIALLY_AVAILABLE')}
                  />
                  {order.product.some((item) => item.status === 'PARTIALLY_AVAILABLE') && (
                     <MissingProducts data={order.product.filter((item) => item.status === 'PARTIALLY_AVAILABLE')} />
                  )}
                  {order.product.some((item) => item.wholesaleName === 'Motorro') && (
                     <ContainedButton
                        sx={{ alignSelf: 'end', mx: '18%' }}
                        variant="contained"
                        onClick={() => window.open(order.paymentLink, '_blank')}
                     >
                        {t('payForOrder')}
                     </ContainedButton>
                  )}
                  <Box sx={{ display: 'flex', gap: 2, width: '63%', justifyContent: 'flex-end' }}>
                     <ContainedButton onClick={() => navigate('/dashboard')} variant="contained">
                        {t('backToMain')}
                     </ContainedButton>
                     {order.product.some(
                        (item) => item.status === 'WHOLESALE_CONNECTION_PROBLEM' || item.status === 'NOT_AVAILABLE'
                     ) &&
                        !isReported && (
                           <OutlinedButton
                              onClick={() => handleReportProblem(order.uuid, order.cartUuid, order.product)}
                              variant="outlined"
                              disabled={isReporting}
                           >
                              {t('reportOrder')}
                           </OutlinedButton>
                        )}
                  </Box>
               </>
            )
         })
      }
      return (
         <>
            <OrderedTable
               formattedGrossValue={state.formattedGrossValue}
               data={state.product.filter((item) => item.status !== 'PARTIALLY_AVAILABLE')}
            />
            {state.product.some((item) => item.status === 'PARTIALLY_AVAILABLE') && (
               <MissingProducts data={state.product.filter((item) => item.status === 'PARTIALLY_AVAILABLE')} />
            )}
            <Box sx={{ display: 'flex', gap: 2 }}>
               <ContainedButton onClick={() => navigate('/dashboard')} variant="contained">
                  {t('backToMain')}
               </ContainedButton>
               {state.product.some(
                  (item) => item.status === 'WHOLESALE_CONNECTION_PROBLEM' || item.status === 'NOT_AVAILABLE'
               ) &&
                  !isReported && (
                     <ContainedButton
                        onClick={() => handleReportProblem(state.uuid, state.cartUuid, state.product)}
                        sx={{ alignSelf: 'end', mx: '18%' }}
                        variant="contained"
                        disabled={isReporting}
                     >
                        {t('reportOrder')}
                     </ContainedButton>
                  )}
            </Box>
         </>
      )
   }

   useEffect(() => {
      if (!state) {
         navigate('/')
      }
   }, [state, navigate])

   return state ? (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px', py: 10, width: '100%' }}>
         <Typography variant="h4" color="primary">
            {t('summary')}
         </Typography>
         {renderContent()}
      </Box>
   ) : (
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
         <CircularProgress color="primary" />
      </Box>
   )
}
