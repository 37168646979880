import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import Tooltip from '@mui/material/Tooltip'

import { AdditionalServiceCartType, EditServicePayload } from 'shared/store/useCartSummary/types'
import { useUpdateServiceMutation } from 'shared/store/useCartSummary'
import invalidateCarts from 'shared/utils/helpers/invalidateCarts'
import { MAX_PRODUCT_QUANTITY } from 'shared/consts'

import {
   StyledTableRow,
   StyledTableCell,
   QuantityHolder,
   StyledQuantityButton,
   StyledInput,
   StyledDeleteIcon,
} from '../../../styles'
import { ClientViewPricesType, ModalInfoType } from '../../../types'

interface Props {
   setModalInfo: (modalInfo: ModalInfoType | undefined) => void
   additionalServices: AdditionalServiceCartType[]
   cartUuid: string
   clientView: boolean
}

export default function AdditionalServicesDesktop({ setModalInfo, additionalServices, clientView, cartUuid }: Props) {
   const [clientPrices, setClientPrices] = useState<ClientViewPricesType>([])
   const { t } = useTranslation(['TRANSLATION', 'MAIN_CART'])
   const { mutate, isLoading } = useUpdateServiceMutation()
   const queryClient = useQueryClient()

   function changeClientPrice(uuid: string, clientPrice: number, taxRate: number, type: 'NET' | 'GROSS') {
      let tempClientPrice
      if (type === 'NET') {
         tempClientPrice = (clientPrice * (1 + taxRate / 100)).toFixed(2)
         updateProduct(uuid, undefined, clientPrice.toFixed(2), tempClientPrice)
      } else {
         tempClientPrice = (clientPrice / (1 + taxRate / 100)).toFixed(2)
         updateProduct(uuid, undefined, tempClientPrice, clientPrice.toFixed(2))
      }
   }

   function updateProduct(uuid: string, quantity?: number, netPrice?: string, grossPrice?: string) {
      const mutatePayload: EditServicePayload = {
         netPrice,
         grossPrice,
         quantity,
      }
      mutate(
         { payload: mutatePayload, cartUuid, serviceUuid: uuid },
         {
            onSuccess: () => {
               invalidateCarts(queryClient)
               setModalInfo(undefined)
            },
         }
      )
   }

   function changeStateClientPrice(newClientPrice: number, uuid: string, taxRate: number) {
      setClientPrices((prevState) => {
         const newState = prevState.map((product) => {
            if (product.uuid === uuid) {
               return {
                  ...product,
                  clientPriceNet: Number(newClientPrice.toFixed(2)),
                  clientPriceGross: Number((newClientPrice * (1 + taxRate / 100)).toFixed(2)),
               }
            }
            return product
         })

         return newState
      })
   }

   useEffect(() => {
      const tempClientPrices: ClientViewPricesType = []
      additionalServices.forEach((product) => {
         tempClientPrices.push({
            uuid: product.uuid,
            clientPriceNet: Number(product.netPrice),
            clientPriceGross: Number(product.grossPrice),
         })
      })
      setClientPrices([...tempClientPrices])
   }, [additionalServices])

   return additionalServices.length ? (
      <>
         {additionalServices.map((service) => (
            <StyledTableRow key={service.uuid}>
               <Tooltip title={service.name}>
                  <StyledTableCell colSpan={clientView ? 2 : 3}>{service.name}</StyledTableCell>
               </Tooltip>
               <StyledTableCell>
                  <QuantityHolder>
                     <StyledQuantityButton
                        type="button"
                        disabled={service.quantity === 0 || isLoading}
                        onClick={() => updateProduct(service.uuid, service.quantity - 1)}
                     >
                        -
                     </StyledQuantityButton>
                     <StyledQuantityButton
                        type="button"
                        onClick={() =>
                           setModalInfo({
                              modalType: 'QUANTITY',
                              serviceUuid: service.uuid,
                              changeServiceQuantity: true,
                              uuid: cartUuid,
                              quantity: service.quantity,
                           })
                        }
                        style={{ fontWeight: 'normal' }}
                     >
                        {service.quantity}
                     </StyledQuantityButton>
                     <StyledQuantityButton
                        type="button"
                        disabled={service.quantity === MAX_PRODUCT_QUANTITY || isLoading}
                        onClick={() => updateProduct(service.uuid, service.quantity + 1)}
                     >
                        +
                     </StyledQuantityButton>
                  </QuantityHolder>
               </StyledTableCell>
               <StyledTableCell>
                  {clientView ? (
                     <>
                        <Tooltip title={t('MAIN_CART:clickToEdit') || ''}>
                           <StyledInput
                              onBlur={(e) =>
                                 changeClientPrice(service.uuid, Number(e.target.value), service.taxRate, 'NET')
                              }
                              onFocus={(e) => e.target.select()}
                              type="Number"
                              onChange={(e) =>
                                 changeStateClientPrice(Number(e.target.value), service.uuid, service.taxRate)
                              }
                              value={clientPrices.find((item) => item.uuid === service.uuid)?.clientPriceNet}
                           />
                        </Tooltip>
                        {service.preferredCurrency}
                     </>
                  ) : (
                     service.formattedNetPrice
                  )}
               </StyledTableCell>
               <StyledTableCell>{service.taxRate}%</StyledTableCell>
               {clientView && (
                  <StyledTableCell>
                     <Tooltip title={t('MAIN_CART:clickToEdit') || ''}>
                        <StyledInput
                           onBlur={(e) =>
                              changeClientPrice(service.uuid, Number(e.target.value), service.taxRate, 'GROSS')
                           }
                           type="Number"
                           onFocus={(e) => e.target.select()}
                           onChange={(e) =>
                              changeStateClientPrice(
                                 Number(e.target.value) / (1 + service.taxRate / 100),
                                 service.uuid,
                                 service.taxRate
                              )
                           }
                           value={clientPrices.find((item) => item.uuid === service.uuid)?.clientPriceGross}
                        />
                     </Tooltip>
                     {service.preferredCurrency}
                  </StyledTableCell>
               )}
               <StyledTableCell>{service.formattedGrossValue}</StyledTableCell>
               <StyledTableCell />
               <StyledTableCell>
                  <StyledDeleteIcon
                     onClick={() =>
                        setModalInfo({
                           modalType: 'DELETE_SERVICE',
                           serviceUuid: service.uuid,
                           uuid: cartUuid,
                           index: service.name,
                           deleteService: true,
                        })
                     }
                  />
               </StyledTableCell>
               {!clientView && <StyledTableCell />}
            </StyledTableRow>
         ))}
      </>
   ) : null
}
