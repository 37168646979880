import React from 'react'
import { MUIDataTableColumn } from 'mui-datatables'

import { sortNumber } from 'shared/utils/helpers/sorters'
import TableHeadCell from 'shared/components/TableHeadCell'

import PriceComponent from '../Elements/PriceComponent'
import DeliveryTime from '../Elements/DeliveryTime'
import SalerLogo from '../Elements/SalerLogo'
import DepartmentAvailability from '../Elements/DepartmentAvailability'

const columns: MUIDataTableColumn[] = [
   // 0
   {
      name: 'wholesaleName',
      label: 'wholesaler',
      options: {
         sort: false,
         filter: false,
         customBodyRender: (value, tableMeta) => React.createElement(SalerLogo, { value, tableMeta }),
      },
   },
   // 1
   {
      name: 'netPrice',
      options: {
         filter: false,
         searchable: false,
         display: 'excluded',
      },
   },
   // 2
   {
      name: 'formattedNetDepositPrice',
      options: {
         filter: false,
         searchable: false,
         display: 'excluded',
      },
   },
   // 3
   {
      name: 'convertedFormattedNetDepositPrice',
      options: {
         filter: false,
         searchable: false,
         display: 'excluded',
      },
   },
   // 4
   {
      name: 'formattedNetPrice',
      options: {
         sort: true,
         filter: false,
         customHeadRender: (columnMeta, updateDirection, sortOrder) =>
            React.createElement(TableHeadCell, {
               value: columnMeta.name,
               updateDirection,
               index: columnMeta.index,
               sortName: sortOrder.name,
               sortDirection: sortOrder.direction,
            }),
         customBodyRender: (value, tableMeta) =>
            React.createElement(PriceComponent, { value, tableMeta, priceType: 'NET' }),

         sortCompare: (order) => {
            return (obj1: any, obj2: any) => {
               const isObj1NullOrZero = obj1.rowData[16] === null || obj1.rowData[16] === 0
               const isObj2NullOrZero = obj2.rowData[16] === null || obj2.rowData[16] === 0

               if (obj1.rowData[1] && obj2.rowData[1]) {
                  if (isObj1NullOrZero && !isObj2NullOrZero) {
                     return 1
                  }

                  if (!isObj1NullOrZero && isObj2NullOrZero) {
                     return -1
                  }
               }

               if (typeof obj1.rowData[5] === 'number' && typeof obj2.rowData[5] === 'number') {
                  return order === 'asc'
                     ? sortNumber(obj1.rowData[5], obj2.rowData[5])
                     : -sortNumber(obj1.rowData[5], obj2.rowData[5])
               }
               if (typeof obj1.rowData[1] === 'number' && typeof obj2.rowData[1] === 'number') {
                  return order === 'asc'
                     ? sortNumber(obj1.rowData[1], obj2.rowData[1])
                     : -sortNumber(obj1.rowData[1], obj2.rowData[1])
               }
               if (!obj1.rowData[1] && obj2.rowData[1]) {
                  return 1
               }
               if (obj1.rowData[1] && !obj2.rowData[1]) {
                  return -1
               }
               return 1
            }
         },
      },
   },
   // 5
   {
      name: 'convertedNetPrice',
      options: {
         filter: false,
         searchable: false,
         display: 'false',
      },
   },
   // 6
   {
      name: 'formattedConvertedNetPrice',
      options: {
         sort: true,
         filter: false,
         display: true,
         searchable: false,
         customHeadRender: (columnMeta, updateDirection, sortOrder) =>
            React.createElement(TableHeadCell, {
               value: columnMeta.name,
               updateDirection,
               index: columnMeta.index,
               sortName: sortOrder.name,
               sortDirection: sortOrder.direction,
            }),
         sortCompare: (order) => {
            return (obj1: any, obj2: any) => {
               const isObj1NullOrZero = obj1.rowData[16] === null || obj1.rowData[16] === 0
               const isObj2NullOrZero = obj2.rowData[16] === null || obj2.rowData[16] === 0

               if (obj1.rowData[1] && obj2.rowData[1]) {
                  if (isObj1NullOrZero && !isObj2NullOrZero) {
                     return 1
                  }

                  if (!isObj1NullOrZero && isObj2NullOrZero) {
                     return -1
                  }
               }

               if (typeof obj1.rowData[5] === 'number' && typeof obj2.rowData[5] === 'number') {
                  return order === 'asc'
                     ? sortNumber(obj1.rowData[5], obj2.rowData[5])
                     : -sortNumber(obj1.rowData[5], obj2.rowData[5])
               }
               if (typeof obj1.rowData[1] === 'number' && typeof obj2.rowData[1] === 'number') {
                  return order === 'asc'
                     ? sortNumber(obj1.rowData[1], obj2.rowData[1])
                     : -sortNumber(obj1.rowData[1], obj2.rowData[1])
               }
               if (!obj1.rowData[1] && obj2.rowData[1]) {
                  return 1
               }
               if (obj1.rowData[1] && !obj2.rowData[1]) {
                  return -1
               }
               return 1
            }
         },
      },
   },
   // 7
   {
      name: 'grossPrice',
      options: {
         filter: false,
         searchable: false,
         display: 'false',
      },
   },
   // 8
   {
      name: 'convertedGrossPrice',
      options: {
         filter: false,
         searchable: false,
         display: 'false',
      },
   },
   // 9
   {
      name: 'formattedGrossDepositPrice',
      options: {
         filter: false,
         searchable: false,
         display: 'false',
      },
   },
   // 10
   {
      name: 'convertedFormattedGrossDepositPrice',
      options: {
         filter: false,
         searchable: false,
         display: 'false',
      },
   },
   // 11
   {
      name: 'formattedGrossPrice',
      options: {
         sort: true,
         filter: false,
         customHeadRender: (columnMeta, updateDirection, sortOrder) =>
            React.createElement(TableHeadCell, {
               value: columnMeta.name,
               updateDirection,
               index: columnMeta.index,
               sortName: sortOrder.name,
               sortDirection: sortOrder.direction,
            }),
         customBodyRender: (value, tableMeta) =>
            React.createElement(PriceComponent, { value, tableMeta, priceType: 'GROSS' }),
         sortCompare: (order) => {
            return (obj1: any, obj2: any) => {
               const isObj1NullOrZero = obj1.rowData[16] === null || obj1.rowData[16] === 0
               const isObj2NullOrZero = obj2.rowData[16] === null || obj2.rowData[16] === 0

               if (obj1.rowData[1] && obj2.rowData[1]) {
                  if (isObj1NullOrZero && !isObj2NullOrZero) {
                     return 1
                  }

                  if (!isObj1NullOrZero && isObj2NullOrZero) {
                     return -1
                  }
               }

               if (typeof obj1.rowData[5] === 'number' && typeof obj2.rowData[5] === 'number') {
                  return order === 'asc'
                     ? sortNumber(obj1.rowData[5], obj2.rowData[5])
                     : -sortNumber(obj1.rowData[5], obj2.rowData[5])
               }
               if (typeof obj1.rowData[1] === 'number' && typeof obj2.rowData[1] === 'number') {
                  return order === 'asc'
                     ? sortNumber(obj1.rowData[1], obj2.rowData[1])
                     : -sortNumber(obj1.rowData[1], obj2.rowData[1])
               }
               if (!obj1.rowData[1] && obj2.rowData[1]) {
                  return 1
               }
               if (obj1.rowData[1] && !obj2.rowData[1]) {
                  return -1
               }
               return 1
            }
         },
      },
   },
   // 12
   {
      name: 'formattedConvertedGrossPrice',
      options: {
         sort: true,
         filter: false,
         display: true,
         searchable: false,
         customHeadRender: (columnMeta, updateDirection, sortOrder) =>
            React.createElement(TableHeadCell, {
               value: columnMeta.name,
               updateDirection,
               index: columnMeta.index,
               sortName: sortOrder.name,
               sortDirection: sortOrder.direction,
            }),
         sortCompare: (order) => {
            return (obj1: any, obj2: any) => {
               const isObj1NullOrZero = obj1.rowData[16] === null || obj1.rowData[16] === 0
               const isObj2NullOrZero = obj2.rowData[16] === null || obj2.rowData[16] === 0

               if (obj1.rowData[1] && obj2.rowData[1]) {
                  if (isObj1NullOrZero && !isObj2NullOrZero) {
                     return 1
                  }

                  if (!isObj1NullOrZero && isObj2NullOrZero) {
                     return -1
                  }
               }

               if (typeof obj1.rowData[5] === 'number' && typeof obj2.rowData[5] === 'number') {
                  return order === 'asc'
                     ? sortNumber(obj1.rowData[5], obj2.rowData[5])
                     : -sortNumber(obj1.rowData[5], obj2.rowData[5])
               }
               if (typeof obj1.rowData[1] === 'number' && typeof obj2.rowData[1] === 'number') {
                  return order === 'asc'
                     ? sortNumber(obj1.rowData[1], obj2.rowData[1])
                     : -sortNumber(obj1.rowData[1], obj2.rowData[1])
               }
               if (!obj1.rowData[1] && obj2.rowData[1]) {
                  return 1
               }
               if (obj1.rowData[1] && !obj2.rowData[1]) {
                  return -1
               }
               return 1
            }
         },
      },
   },
   // 13
   {
      name: 'firstDepartmentAvailability',
      options: {
         sort: true,
         filter: false,
         customHeadRender: (columnMeta, updateDirection, sortOrder) =>
            React.createElement(TableHeadCell, {
               value: columnMeta.name,
               updateDirection,
               index: columnMeta.index,
               sortName: sortOrder.name,
               sortDirection: sortOrder.direction,
            }),
         customBodyRender: (value, tableMeta) =>
            React.createElement(DepartmentAvailability, { value, data: tableMeta, department: 1 }),
      },
   },
   // 14
   {
      name: 'secondDepartmentAvailability',
      options: {
         sort: true,
         filter: false,
         customHeadRender: (columnMeta, updateDirection, sortOrder) =>
            React.createElement(TableHeadCell, {
               value: columnMeta.name,
               updateDirection,
               index: columnMeta.index,
               sortName: sortOrder.name,
               sortDirection: sortOrder.direction,
            }),
         customBodyRender: (value, tableMeta) =>
            React.createElement(DepartmentAvailability, { value, data: tableMeta, department: 2 }),
      },
   },
   // 15
   {
      name: 'thirdDepartmentAvailability',
      options: {
         sort: true,
         filter: false,
         customHeadRender: (columnMeta, updateDirection, sortOrder) =>
            React.createElement(TableHeadCell, {
               value: columnMeta.name,
               updateDirection,
               index: columnMeta.index,
               sortName: sortOrder.name,
               sortDirection: sortOrder.direction,
            }),
         customBodyRender: (value, tableMeta) =>
            React.createElement(DepartmentAvailability, { value, data: tableMeta, department: 3 }),
      },
   },
   // 16
   {
      name: 'totalAvailability',
      options: {
         sort: true,
         filter: false,
         customHeadRender: (columnMeta, updateDirection, sortOrder) =>
            React.createElement(TableHeadCell, {
               value: columnMeta.name,
               updateDirection,
               index: columnMeta.index,
               sortName: sortOrder.name,
               sortDirection: sortOrder.direction,
            }),
         customBodyRender: (value, tableMeta) =>
            value > tableMeta.rowData[21] ? `> ${tableMeta.rowData[21]}` : value || 0,
      },
   },
   // 17
   {
      name: 'minutesToDeliveryStart',
      options: {
         sort: true,
         filter: false,
         customHeadRender: (columnMeta, updateDirection, sortOrder) =>
            React.createElement(TableHeadCell, {
               value: columnMeta.name,
               updateDirection,
               index: columnMeta.index,
               sortName: sortOrder.name,
               sortDirection: sortOrder.direction,
            }),
         customBodyRender: (value, tableMeta) => {
            return tableMeta.rowData[16] === null || tableMeta.rowData[16] === 0 ? null : DeliveryTime(value)
         },
      },
   },
   // 18
   {
      name: 'afterSalesBonus',
      options: {
         display: 'excluded',
      },
   },
   // 19
   {
      name: 'errorMessage',
      options: {
         display: 'excluded',
      },
   },
   // 20
   {
      name: 'requirePrepayment',
      options: {
         display: 'excluded',
      },
   },
   // 21
   {
      name: 'wholesaleInfiniteAvailabilityThreshold',
      options: {
         display: 'excluded',
      },
   },
   // 22
   {
      name: 'wholesaleMessage',
      options: {
         display: 'excluded',
      },
   },
]

export default columns
