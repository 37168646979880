import { AxiosError } from 'axios'

import { ChangeQuantityPayload } from 'shared/store/useCartSummary/types'
import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient, verificationRequest } from 'shared/lib'

import {
   CheckTokenResult,
   FetchReflinkDetailsType,
   VerifySMSCodeResult,
   EndpointsPayload,
   FetchOrderReflinkDetails,
   IncludeProductInOrderPayload,
} from '../types'

export function checkTokenService(token: string) {
   let errMessage = ''

   return httpClient
      .post<CheckTokenResult>(`/order/reflink/validate/${token}`)
      .then((res) => ({ status: res.status, valid: res.data.valid }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('ORDER_REFLINK checkToken service', errMessage, 'error')
         throw error
      })
}

export function getReflinkDetails(token: string): Promise<FetchReflinkDetailsType> {
   let errMesssage = ''

   return httpClient
      .get(`order/reflink/?token=${token}`)
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMesssage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMesssage = error.message
         }
         consoleMessage('ORDER_REFLINK getReflinkDetails service', errMesssage, 'error')
         throw error
      })
}

export function veryfiSMSCodeService(smsCode: string): Promise<VerifySMSCodeResult> {
   let errMessage
   return verificationRequest
      .post('sms/verify', { smsCode })
      .then((res) => ({ valid: res.data.valid }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('ORDER_REFLINK verifySMSCode service', errMessage, 'error')
         throw error
      })
}

export function fetchCartSummary(payload: EndpointsPayload): Promise<FetchOrderReflinkDetails> {
   let errMesssage = ''
   return httpClient
      .post('order/reflink/getCartSummary?actual=true', payload)
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('No data in request')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMesssage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMesssage = error.message
         }
         consoleMessage('ORDER_REFLINK fetchCartSummary service', errMesssage, 'error')
         throw error
      })
}

export function changeQuantity(payload: ChangeQuantityPayload) {
   let errMessage

   return httpClient
      .patch<Result>('order/reflink/product/changeQuantity', payload)
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('ORDER_REFLINK changeQuantity service', errMessage, 'error')
         throw error
      })
}

export function changeIncludeInOrder(payload: IncludeProductInOrderPayload) {
   let errMessage

   return httpClient
      .patch<Result>('order/reflink/product/includeProductInOrder', payload)
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('ORDER_REFLINK changeIncludeInOrder service', errMessage, 'error')
         throw error
      })
}
