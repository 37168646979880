import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useFormikContext } from 'formik'
import Alert from '@mui/material/Alert'
import Fade from '@mui/material/Fade'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import { verificationRequest } from 'shared/lib'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import InputField from 'shared/components/InputField'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import { StyledContactLink } from 'views/Unauthorized/views/Homepage/styles'
import ReCAPTCHA from 'react-google-recaptcha'

interface Props {
   recaptchaRef: React.RefObject<ReCAPTCHA>
}

export function ThirdStep({ recaptchaRef }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'REGISTER'])
   const { values, setFieldValue, resetForm } = useFormikContext<RegisterInitialValues>()
   const [isLoading, setIsLoading] = useState(false)
   const [smsCodeError, setSmsCodeError] = useState('')
   const [smsCodeSuccess, setSmsCodeSuccess] = useState('')
   const [recaptcha, setRecaptcha] = useState('')
   const [recaptchaRequired, setRecaptchaRequired] = useState(false)

   async function getSmsCode() {
      const phoneNumber = values.telephoneNumber
      setSmsCodeError('')
      setSmsCodeSuccess('')
      setIsLoading(true)
      try {
         if (recaptchaRequired) {
            await verificationRequest.post(`sms/send?g-recaptcha-response=${recaptcha}`, { phoneNumber })
         } else {
            await verificationRequest.post('sms/send', { phoneNumber })
         }
         setSmsCodeSuccess(t('REGISTER:mainSite.smsCodeSuccess', { phone: phoneNumber }))
         resetForm({ values: { ...values, smsCode: '' } })
      } catch (error) {
         if (isAxiosError<ErrorType>(error)) {
            if (error.response?.data.errors[0].code) {
               if (error.response.data.errors[0].code === 'errors.captcha.required') {
                  setRecaptchaRequired(true)
               }
               setSmsCodeError(t(`TRANSLATION:${error.response?.data.errors[0].code}`))
            } else {
               setSmsCodeError(t('apiErrors.500'))
            }
         }
      } finally {
         setIsLoading(false)
      }
   }

   useEffect(() => {
      if (!recaptcha) {
         setSmsCodeError('')
      }
   }, [recaptcha])

   return (
      <Container>
         <Grid justifyContent="center" container sx={{ mt: 7, mb: 3 }}>
            <Grid item xs={12}>
               <Typography align="center">{t('REGISTER:mainSite:sendRequestSMS')}</Typography>
            </Grid>
            <Grid sx={{ mt: 2 }} item xs={12} textAlign="center">
               <FormControlLabel
                  control={
                     <Checkbox
                        checked={values.acceptRegulations}
                        onChange={() => setFieldValue('acceptRegulations', !values.acceptRegulations)}
                     />
                  }
                  label={
                     <Typography sx={{ my: 2 }} variant="body1">
                        {t('UNAUTHORIZED:regulations')}
                        <StyledContactLink href="https://motorro.pl/regulamin/" target="_blank">
                           {t('UNAUTHORIZED:termsOfUseRegister')}
                        </StyledContactLink>
                        {t('UNAUTHORIZED:andRegister')}
                        <StyledContactLink href="https://motorro.pl/polityka-prywatnosci/" target="_blank">
                           {t('UNAUTHORIZED:privacyPolicyRegister')}
                        </StyledContactLink>
                        {t('UNAUTHORIZED:accept')}
                     </Typography>
                  }
               />
            </Grid>
            <Grid
               item
               xs={12}
               sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  gap: 2,
                  alignItems: 'center',
                  width: '100%',
               }}
            >
               <ContainedButton
                  variant="contained"
                  size="large"
                  sx={{ mt: 2 }}
                  onClick={getSmsCode}
                  disabled={isLoading || !values.acceptRegulations || (recaptchaRequired && !recaptcha)}
               >
                  {t('REGISTER:mainSite:getCode')}
               </ContainedButton>
               {recaptchaRequired && (
                  <ReCAPTCHA
                     sitekey="6LebPrIUAAAAAEtQ6YCkPH5RO-V1WNeAn5-dNCOc"
                     ref={recaptchaRef}
                     onChange={(value) => setRecaptcha(value || '')}
                  />
               )}
            </Grid>
            <Grid>
               {smsCodeError && (
                  <Alert severity="error" sx={{ mt: 2, textAlign: 'center' }}>
                     {smsCodeError}
                  </Alert>
               )}
               {smsCodeSuccess && (
                  <Alert severity="success" sx={{ mt: 2, textAlign: 'center' }}>
                     {smsCodeSuccess}
                  </Alert>
               )}
            </Grid>
         </Grid>
         {smsCodeSuccess && (
            <Fade in timeout={1500}>
               <Grid justifyContent="center" container>
                  <Grid item xs={12} sx={{ mb: 2 }}>
                     <Typography align="center">{t('REGISTER:mainSite:completeSmsCode')}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                     <InputField<RegisterInitialValues>
                        type="smsCode"
                        label={t('TRANSLATION:formFields.smsCode')}
                        name="smsCode"
                     />
                  </Grid>
               </Grid>
            </Fade>
         )}
      </Container>
   )
}
