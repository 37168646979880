import { AxiosError } from 'axios'

import consoleMessage from 'shared/utils/helpers/consoleMessage'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { httpClient } from 'shared/lib'

import { FetchProductsPayload, FetchProductsResult, Result, SendDuplicatesPayload, VoteType } from './types'

export function fetchProducts(payload: FetchProductsPayload): Promise<FetchProductsResult> {
   let errMessage = ''

   return httpClient
      .post('products/search', {
         ...payload,
      })
      .then((res) => res.data)
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('PRODUCTS_LIST fetchProducts service', errMessage, 'error')
         throw error
      })
}

export function sendDuplicates(payload: SendDuplicatesPayload) {
   let errMessage = ''

   return httpClient
      .post<Result>('duplicates/add', payload)
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('PRODUCTS_LIST sendDuplicates service', errMessage, 'error')
         throw error
      })
}

export async function sendSubstituteVote(
   productUuid: string,
   replacementProductUuid: string,
   vote: Exclude<VoteType, 'MAIN'>
): Promise<Result> {
   let errMessage = ''

   return httpClient
      .post(`products/${productUuid}/replacements/${replacementProductUuid}/vote`, {
         vote,
      })
      .then((res) => ({ status: res.status }))
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('PRODUCTS_LIST sendSubstituteVote service', errMessage, 'error')
         throw error
      })
}

export async function getSubstitutesService(
   productUuid: string,
   showUnverified?: boolean
): Promise<FetchProductsResult> {
   let errMessage = ''

   if (showUnverified) {
      return httpClient
         .get(`/products/${productUuid}/replacements?showMode=UNVERIFIED`)
         .then((res) => {
            if (res.data) {
               return res.data
            }
            throw new Error('noData')
         })
         .catch((error: Error | AxiosError<ErrorType>) => {
            if (isAxiosError<ErrorType>(error)) {
               errMessage = error.response?.data?.errors[0].defaultMessage || ''
            } else {
               errMessage = error.message
            }
            consoleMessage('PRODUCTS_LIST getSubstitutesService service', errMessage, 'error')
            throw error
         })
   }

   return httpClient
      .get(`/products/${productUuid}/replacements`)
      .then((res) => {
         if (res.data) {
            return res.data
         }
         throw new Error('noData')
      })
      .catch((error: Error | AxiosError<ErrorType>) => {
         if (isAxiosError<ErrorType>(error)) {
            errMessage = error.response?.data?.errors[0].defaultMessage || ''
         } else {
            errMessage = error.message
         }
         consoleMessage('PRODUCTS_LIST getSubstitutesService service', errMessage, 'error')
         throw error
      })
}
