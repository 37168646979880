import React, { useEffect, useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import CircularProgress from '@mui/material/CircularProgress'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import LinkIcon from '@mui/icons-material/Link'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'
import Box from '@mui/material/Box'
import { useQueryClient } from 'react-query'

import { UpdateCartInfoPayload, RemoveProductPayload, UpdateProductType } from 'shared/store/useCartSummary/types'
import { useLogisticMinimumsQuery } from 'shared/store/useLogisticMinimums'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import InputField from 'shared/components/InputField'
import {
   useCartSummaryQuery,
   useNewCartMutation,
   useRemoveProductMutation,
   useUpdateCartInfoMutation,
   useUpdatePricesMutation,
   useUpdateProductMutation,
} from 'shared/store/useCartSummary'
import HiddenWholesalerLogo from 'shared/components/HiddenWholesalerLogo'
import { useNotificationContext } from 'shared/context/Notifications'
import RadiusTableWrapper from 'shared/components/RadiusTableWrapper'
import invalidateCarts from 'shared/utils/helpers/invalidateCarts'
import isAxiosError from 'shared/utils/helpers/isAxiosError'
import { useProfileQuery } from 'shared/store/useProfile'
import getPrice from 'shared/utils/helpers/getPrice'
import useLogin from 'shared/store/useLogin'
import { UserRoles } from 'shared/consts'

import UnavailableProductsTableMobile from './UnavailableProductsTableMobile'
import { MobileTableHeaders } from '../../data/TableHeaders'
import { CartDetailsType, ModalInfoType } from '../../types'
import TableForClientMobile from './TableForClientMobile'
import MainTableMobile from './MainTableMobile'
import schema from '../../schema'
import Modal from '../Modal'
import {
   StyledTitleTableCell,
   StyledTitleRow,
   StyledTableCell,
   GreenHour,
   RedHour,
   YellowHour,
   StyledFooterTableCell,
} from '../../styles'
import generateReflinkService from '../../services/generateReflinkService'
import ServicesMobile from './ServicesMobile'

interface Props {
   handleOrderSubmit: () => void
   isOrdering: boolean
}

export default function MainCartMobile({ handleOrderSubmit, isOrdering }: Props) {
   const { t } = useTranslation(['TRANSLATION', 'MAIN_CART'])
   const { data: summaryData, isFetched } = useCartSummaryQuery()
   const [modalInfo, setModalInfo] = useState<ModalInfoType>()
   const [clientView, setClientView] = useState(false)
   const queryClient = useQueryClient()
   const { addNotification } = useNotificationContext()
   const { data: logisticMinimums, isFetched: isLogisticMinimumsFetched } = useLogisticMinimumsQuery(false)
   const { mutate: updateCartInfoMutate, isLoading: updateCartInfoIsLoading } = useUpdateCartInfoMutation()
   const { mutate: UpdateProductMutate, isLoading: updateProductIsLoading } = useUpdateProductMutation()
   const { mutate: removeProductFromCartMutate } = useRemoveProductMutation()
   const { mutate: updatePricesMutate } = useUpdatePricesMutation()
   const { mutate: createNewCartMutate } = useNewCartMutation()
   const { data: userData } = useProfileQuery()
   const { user } = useLogin()

   function getFormattedDeliveryTime(minutes: number) {
      if (minutes > 600) return '> 10 h'
      if (minutes === 0) return '0 min'
      if (minutes < 5) return '< 5 min'
      if (minutes > 300) return '> 5 h'
      if (minutes > 240) return '> 4 h'
      if (minutes > 180) return '> 3 h'
      if (minutes > 120) return '> 2 h'
      if (minutes > 60) return '> 1 h'
      return `${minutes} min`
   }

   function updateProduct(uuid: string, quantity?: number, includeProductInOrder?: boolean, netClientPrice?: number) {
      if (summaryData) {
         const mutatePayload: UpdateProductType = {
            payload: {
               quantity,
               netClientPrice,
               includeProductInOrder,
            },
            cartUuid: summaryData.cartDetailsDTO.cartUuid,
            cartProductUuid: uuid,
         }
         UpdateProductMutate(mutatePayload, {
            onSuccess: () => {
               invalidateCarts(queryClient)
            },
         })
      }
   }

   const formik = useFormik<CartDetailsType>({
      initialValues: {
         cartDescription: '',
         cartName: '',
         cartUuid: '',
      },
      validationSchema: schema,
      onSubmit: () => {},
   })

   const { setValues, values } = formik

   function changeCartName() {
      if (values.cartUuid) {
         const payload: UpdateCartInfoPayload = {
            payload: {
               cartName: values.cartName,
            },
            cartUuid: values.cartUuid,
         }
         updateCartInfoMutate(payload)
      }
   }
   function handleRemoveProduct(uuid: string, removeFromUnavailable?: boolean) {
      if (values.cartUuid) {
         const payload: RemoveProductPayload = {
            cartUuid: values.cartUuid,
            uuid,
            removeFromUnavailable,
         }
         removeProductFromCartMutate(payload, {
            onSettled: () => {
               invalidateCarts(queryClient)
               setModalInfo(undefined)
            },
         })
      }
   }

   function returnMinimumDeliveryTableCell(minutesToDeliveryStart: number) {
      if (minutesToDeliveryStart === -1) {
         return <StyledTableCell />
      }
      if (minutesToDeliveryStart < 5) {
         return (
            <TableCell>
               <RedHour>{getFormattedDeliveryTime(minutesToDeliveryStart)}</RedHour>
            </TableCell>
         )
      }
      if (minutesToDeliveryStart > 60) {
         return (
            <TableCell>
               <GreenHour>{getFormattedDeliveryTime(minutesToDeliveryStart)}</GreenHour>
            </TableCell>
         )
      }
      return (
         <TableCell>
            <YellowHour>{getFormattedDeliveryTime(minutesToDeliveryStart)}</YellowHour>
         </TableCell>
      )
   }

   function generateReflink() {
      if (summaryData) {
         generateReflinkService(summaryData.cartDetailsDTO.cartUuid)
            .then((res) => {
               if (res.status === 200) {
                  setModalInfo({ modalType: 'REFLINK', reflinkURL: res.reflink })
               }
            })
            .catch((error) => {
               if (isAxiosError<ErrorType>(error)) {
                  addNotification(error.response?.data?.errors[0].code || t('MAIN_CART:generateFailed'), 'error')
               } else {
                  addNotification(t('MAIN_CART:generateFailed'), 'error')
               }
            })
      }
   }

   useEffect(() => {
      if (summaryData) {
         setValues({
            ...summaryData.cartDetailsDTO,
         })
      }
   }, [summaryData])

   useEffect(() => {
      setModalInfo(undefined)
   }, [userData?.locationUuid])

   return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
         <FormikProvider value={formik}>
            <Form>
               {isFetched && summaryData ? (
                  <>
                     <Typography variant="body1" sx={{ mb: 1, mt: 4, opacity: 0.5 }}>
                        {t('MAIN_CART:availableProducts')}
                     </Typography>
                     <RadiusTableWrapper sx={{ width: '100%' }}>
                        <Table
                           sx={{
                              backgroundColor: 'rgba(255,255,255,0.7)',
                           }}
                        >
                           <TableHead sx={{ padding: 3, backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                              <TableRow>
                                 <TableCell colSpan={4} sx={{ margin: 1 }}>
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          gap: 3,
                                          justifyContent: 'space-between',
                                       }}
                                    >
                                       <InputField<CartDetailsType>
                                          name="cartName"
                                          onBlur={changeCartName}
                                          disabled={updateCartInfoIsLoading}
                                          fullWidth={false}
                                          variant="standard"
                                          onFocus={(e) => e.target.select()}
                                          label={t('MAIN_CART:cartName')}
                                       />
                                       <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                          {clientView ? (
                                             <ContainedButton
                                                type="button"
                                                variant="contained"
                                                onClick={() => setClientView((prevState) => !prevState)}
                                             >
                                                {t('MAIN_CART:clientView')}
                                             </ContainedButton>
                                          ) : (
                                             <OutlinedButton
                                                type="button"
                                                variant="outlined"
                                                onClick={() => setClientView((prevState) => !prevState)}
                                             >
                                                {t('MAIN_CART:clientView')}
                                             </OutlinedButton>
                                          )}
                                          <ContainedButton
                                             type="button"
                                             onClick={createNewCartMutate}
                                             variant="contained"
                                             color="primary"
                                          >
                                             {t('MAIN_CART:createCart')}
                                          </ContainedButton>
                                       </Box>
                                    </Box>
                                 </TableCell>
                              </TableRow>
                              <StyledTitleRow>
                                 {MobileTableHeaders.map((item) => (
                                    <StyledTitleTableCell key={item}>
                                       {t(`TRANSLATION:columnNames.${item}`)}
                                    </StyledTitleTableCell>
                                 ))}
                                 <StyledTableCell />
                              </StyledTitleRow>
                           </TableHead>
                           <TableBody>
                              {clientView ? (
                                 <TableForClientMobile
                                    products={summaryData.products.filter(
                                       (product) => product.status !== 'NOT_AVAILABLE'
                                    )}
                                    isProductUpdating={updateProductIsLoading}
                                    handleProductUpdate={updateProduct}
                                    setModalInfo={setModalInfo}
                                 />
                              ) : (
                                 <MainTableMobile
                                    setModalInfo={setModalInfo}
                                    products={summaryData.products.filter(
                                       (product) => product.status !== 'NOT_AVAILABLE'
                                    )}
                                    isProductUpdating={updateProductIsLoading}
                                    handleProductUpdate={updateProduct}
                                 />
                              )}
                              <ServicesMobile
                                 cartUuid={summaryData.cartDetailsDTO.cartUuid}
                                 services={summaryData.additionalServices}
                                 setModalInfo={setModalInfo}
                                 clientView={clientView}
                              />
                              <StyledTitleRow>
                                 <StyledFooterTableCell
                                    sx={{
                                       display: 'flex',
                                       gap: 1,
                                    }}
                                 >
                                    <p style={{ fontWeight: 'bold', fontSize: 13 }}>
                                       {t('TRANSLATION:columnNames.netSum')}
                                    </p>
                                    <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                       {clientView
                                          ? summaryData.formattedClientNetValue
                                          : summaryData.formattedNetValue}
                                    </p>
                                 </StyledFooterTableCell>
                                 <StyledFooterTableCell />
                                 <StyledFooterTableCell
                                    sx={{
                                       display: 'flex',
                                       gap: 1,
                                    }}
                                 >
                                    <p style={{ fontWeight: 'bold', fontSize: 13 }}>
                                       {t('TRANSLATION:columnNames.grossSum')}
                                    </p>
                                    <p style={{ fontWeight: 'bold', fontSize: 13, color: 'black' }}>
                                       {clientView
                                          ? summaryData.formattedClientGrossValue
                                          : summaryData.formattedGrossValue}
                                    </p>
                                 </StyledFooterTableCell>
                                 <StyledFooterTableCell />
                              </StyledTitleRow>
                           </TableBody>
                        </Table>
                     </RadiusTableWrapper>
                     {summaryData.products.filter((item) => item.status === 'NOT_AVAILABLE').length > 0 && (
                        <Box>
                           <UnavailableProductsTableMobile
                              products={summaryData.products.filter((item) => item.status === 'NOT_AVAILABLE')}
                              setModalInfo={setModalInfo}
                           />
                        </Box>
                     )}
                     <Box
                        sx={{
                           display: 'flex',
                           justifyContent: 'flex-end',
                           gap: 2,
                           margin: '0 auto',
                           paddingTop: 2,
                        }}
                     >
                        <OutlinedButton
                           sx={{ alignSelf: 'flex-start' }}
                           onClick={() =>
                              setModalInfo({
                                 modalType: 'SERVICES',
                                 uuid: summaryData.cartDetailsDTO.cartUuid,
                              })
                           }
                           variant="outlined"
                        >
                           {t('MAIN_CART:services.addService')}
                        </OutlinedButton>
                        <OutlinedButton
                           disabled={summaryData?.products.length === 0}
                           variant="outlined"
                           onClick={generateReflink}
                        >
                           <LinkIcon />
                        </OutlinedButton>
                        <OutlinedButton
                           disabled={summaryData?.products.length === 0}
                           onClick={() => {
                              if (summaryData) {
                                 updatePricesMutate(summaryData.cartDetailsDTO.cartUuid)
                              }
                           }}
                           variant="outlined"
                        >
                           {t('MAIN_CART:updatePrices')}
                        </OutlinedButton>
                        <ContainedButton
                           onClick={handleOrderSubmit}
                           disabled={
                              updateProductIsLoading ||
                              summaryData?.products.length === 0 ||
                              isOrdering ||
                              !summaryData?.products.find((item) => item.includedProductInOrder) ||
                              user?.roles.includes(UserRoles.SALES_REP)
                           }
                           variant="contained"
                        >
                           {t('MAIN_CART:order')}
                        </ContainedButton>
                     </Box>
                     {!clientView ? (
                        <Box
                           sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 1,
                              margin: '0 auto',
                              marginTop: 6,
                              minHeight: 200,
                           }}
                        >
                           <InputField<CartDetailsType>
                              name="cartDescription"
                              sx={{ minHeight: 200 }}
                              multiline
                              rows={5}
                              label={t('MAIN_CART:cartDescription')}
                           />
                           {summaryData.products.length > 0 && (
                              <RadiusTableWrapper sx={{ width: '100%' }}>
                                 <Table
                                    sx={{
                                       backgroundColor: 'rgba(255,255,255,0.7)',
                                    }}
                                 >
                                    <TableHead sx={{ backgroundColor: 'rgba(33,147,224, 0.1)' }}>
                                       <StyledTitleRow>
                                          <StyledTitleTableCell>
                                             {t('TRANSLATION:columnNames.wholesaler')}
                                          </StyledTitleTableCell>
                                          <StyledTitleTableCell>
                                             {userData?.priceType === 'NET'
                                                ? t('MAIN_CART:remainLogisticMinimumsNet')
                                                : t('MAIN_CART:remainLogisticMinimumsGross')}
                                          </StyledTitleTableCell>
                                          <StyledTitleTableCell>
                                             {t('MAIN_CART:timeUntilDeliveryStart')}
                                          </StyledTitleTableCell>
                                       </StyledTitleRow>
                                    </TableHead>
                                    <TableBody>
                                       {isLogisticMinimumsFetched && logisticMinimums ? (
                                          logisticMinimums.logisticMinimums.map((minimum) => (
                                             <TableRow key={minimum.wholesaleUuid}>
                                                <StyledTableCell>
                                                   <HiddenWholesalerLogo
                                                      wholesalerName={minimum.wholesaleName}
                                                      imgHeight="20px"
                                                      imgWidth="auto"
                                                   />
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                   {t('TRANSLATION:price', {
                                                      price: getPrice(
                                                         minimum.remainingAmount,
                                                         userData?.priceType || 'NET'
                                                      ),
                                                   })}
                                                </StyledTableCell>
                                                {returnMinimumDeliveryTableCell(minimum.minutesToDeliveryStart)}
                                             </TableRow>
                                          ))
                                       ) : (
                                          <TableRow>
                                             <StyledTableCell
                                                colSpan={3}
                                                sx={{ display: 'flex', justifyContent: 'center' }}
                                             >
                                                <CircularProgress color="primary" />
                                             </StyledTableCell>
                                          </TableRow>
                                       )}
                                    </TableBody>
                                 </Table>
                              </RadiusTableWrapper>
                           )}
                        </Box>
                     ) : null}
                     <Modal
                        modalInfo={modalInfo}
                        data={summaryData}
                        handleClose={() => setModalInfo(undefined)}
                        handleProductUpdate={updateProduct}
                        handleRemoveProductFromCart={handleRemoveProduct}
                     />
                  </>
               ) : (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                     <CircularProgress />
                  </Box>
               )}
            </Form>
         </FormikProvider>
      </Box>
   )
}
