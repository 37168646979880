import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Form, Formik } from 'formik'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import { useTheme } from '@mui/material/styles'
import ErrorIcon from '@mui/icons-material/Error'
import Stack from '@mui/material/Stack'

import { AddToCartPayload, ModalFormDefaults, ModalProductDetails } from 'views/Authorized/views/ProductDetails/types'
import HiddenWholesalerLogo from 'shared/components/HiddenWholesalerLogo'
import ContainedButton from 'shared/components/Buttons/ContainedButton'
import OutlinedButton from 'shared/components/Buttons/OutlinedButton'
import { Modal } from 'views/Authorized/views/MainCart/types'
import InputField from 'shared/components/InputField'
import { MAX_PRODUCT_QUANTITY } from 'shared/consts'
import { Yup } from 'shared/lib'
import { useProfileQuery } from 'shared/store/useProfile'

const schema = Yup.object().shape({
   cartName: Yup.string().required(),
   clientPrice: Yup.number()
      .required()
      .test({
         name: 'is-proper-value',
         test(value, ctx) {
            const salerPrice = ctx.parent.convertedNetPrice
               ? (ctx.parent.convertedNetPrice as number)
               : (ctx.parent.salerPrice as number)
            if (typeof value === 'number' && value + 0.01 > salerPrice) {
               return true
            }
            return ctx.createError({ message: 'TRANSLATION:minimumValue', params: { value: salerPrice + 0.01 } })
         },
      }),
   salerPrice: Yup.number().required(),
   quantity: Yup.number()
      .required()
      .test({
         name: 'quantity-in-set',
         test(value, ctx) {
            const quantityInSet = ctx.parent.quantityInSet as number
            if (value === quantityInSet || (typeof value === 'number' && value % quantityInSet === 0)) {
               return true
            }
            return ctx.createError({ message: 'TRANSLATION:multipleValue', params: { value: quantityInSet } })
         },
      }),
})

interface Props {
   onClose: () => void
   modalData?: Modal
   onSwapProduct: (payload: AddToCartPayload) => Promise<{ status: number }>
   isLoading: boolean
}

export default function ModalComponent({ onClose, modalData, onSwapProduct, isLoading }: Props) {
   const { data: profileData } = useProfileQuery()
   const { t } = useTranslation(['TRANSLATIONS', 'PRODUCT_DETAILS'])

   const priceType = profileData?.priceType || 'GROSS'
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
   const productDetails: ModalProductDetails = {
      index: '',
      producer: '',
      wholesaleName: modalData?.data.wholesaleName || '',
   }

   function handleOnAddToCart(values: ModalFormDefaults) {
      const payload: AddToCartPayload = {
         netClientPrice:
            priceType === 'NET'
               ? values.clientPrice.toString()
               : (values.clientPrice / (1 + values.taxRate / 100)).toFixed(2).toString(),
         netPrice: values.netPrice.toString(),
         grossPrice: values.grossPrice.toString(),
         currency: values.currency,
         taxRate: values.taxRate,
         quantity: values.quantity,
         wholesaleUuid: modalData?.data.wholesaleUuid as string,
      }
      onSwapProduct(payload).then((res) => {
         if (res.status === 200) {
            onClose()
         }
      })
   }

   const renderForm = useCallback(() => {
      if (!modalData) {
         return null
      }
      const { data } = modalData
      const taxRate = data.taxRate || 23
      const netPrice = data.netPrice || 0
      const convertedNetPrice = data.convertedNetPrice || 0
      const totalAvailability = data.totalAvailability || 0
      const quantityInSet = data.quantityInSet || 1
      const grossPrice = data.grossPrice || 0
      const salerPrice = priceType === 'NET' ? netPrice.toString() : grossPrice.toString()
      const currency = data.currency || 'PLN'
      const clientPrice = priceType === 'NET' ? data.clientNetPrice : data.clientGrossPrice

      return (
         <Formik<ModalFormDefaults & { cartName: string }>
            initialValues={{
               clientPrice,
               grossPrice,
               currency,
               taxRate,
               salerPrice,
               convertedNetPrice,
               netPrice,
               quantityInSet,
               quantity: modalData.defaultQuantity || quantityInSet || 1,
               cartName: dayjs().format('DD-MM-YYYY HH:mm:ss'),
            }}
            validationSchema={schema}
            onSubmit={handleOnAddToCart}
         >
            <Form>
               <Stack direction="row" spacing={2} justifyContent="space-between">
                  <div
                     style={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                     }}
                  >
                     <HiddenWholesalerLogo
                        wholesalerName={productDetails.wholesaleName}
                        imgHeight="auto"
                        imgWidth="160px"
                     />
                  </div>
                  <div style={{ flex: 1 }}>
                     <InputField
                        sx={{ marginBottom: 3, marginTop: 2 }}
                        fullWidth
                        variant="standard"
                        type="Number"
                        name="quantity"
                        label={t('TRANSLATION:columnNames.pickQuantity')}
                        InputProps={{
                           inputProps: {
                              min: quantityInSet,
                              max: totalAvailability < 5 ? totalAvailability : MAX_PRODUCT_QUANTITY,
                              step: quantityInSet,
                           },
                        }}
                     />
                     <InputField
                        sx={{ marginBottom: 3 }}
                        fullWidth
                        variant="standard"
                        name={convertedNetPrice ? 'convertedNetPrice' : 'salerPrice'}
                        label={`${t('TRANSLATION:columnNames.price')} (${convertedNetPrice ? 'EUR' : currency})`}
                        disabled
                     />
                     <InputField
                        inputProps={{
                           step: 0.01,
                        }}
                        onFocus={(e) => e.target.select()}
                        fullWidth
                        variant="standard"
                        type="Number"
                        name="clientPrice"
                        label={`${t('MAIN_CART:swap.setClientPrice')} (${convertedNetPrice ? 'EUR' : currency})`}
                     />
                  </div>
               </Stack>
               <Stack direction="row" spacing={2} justifyContent="flex-end" mt={5}>
                  <OutlinedButton variant="outlined" onClick={onClose} disabled={isLoading}>
                     {t('TRANSLATION:cancel')}
                  </OutlinedButton>
                  <ContainedButton type="submit" variant="contained" disabled={isLoading}>
                     {t('TRANSLATION:save')}
                  </ContainedButton>
               </Stack>
            </Form>
         </Formik>
      )
   }, [modalData, isLoading])

   return (
      <Dialog fullScreen={fullScreen} open={!!modalData} onClose={onClose} aria-labelledby="responsive-dialog-title">
         {modalData ? (
            <>
               <DialogTitle id="responsive-dialog-title">
                  <Typography sx={{ textAlign: 'center', color: theme.palette.primary.main, fontSize: 20 }}>
                     {t('MAIN_CART:swap.swapTitle')}
                     {modalData.data.quantityInSet !== 1 && (
                        <Tooltip
                           title={
                              t('MAIN_CART:swap.multipleMustBeMore', {
                                 quantity: modalData.data.quantityInSet,
                              }) || ''
                           }
                        >
                           <ErrorIcon sx={{ mb: -0.7, color: 'red' }} />
                        </Tooltip>
                     )}
                  </Typography>
               </DialogTitle>
               <DialogContent>{renderForm()}</DialogContent>
            </>
         ) : (
            <DialogContent />
         )}
      </Dialog>
   )
}
